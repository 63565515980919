import { DeviceFinder } from 'Guide/device_finder';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('WikiRelatedFrameModule', () => {
   WikiRelatedFrameModule.initialize();
});

const WikiRelatedFrameModule = {
   initialize: function () {
      when($('wikiRelatedInput'), el => {
         DeviceFinder.initialize(el);
      });
   },
};
