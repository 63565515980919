import { Autocompleter } from 'Shared/Autocompleter/Autocompleter';
import { truncate } from 'Shared/utils/text-utils';

// eslint-disable-next-line no-var
export var BlurbFinder = (window.BlurbFinder = new Class({
   Extends: Autocompleter.Request.JSON,

   el: null,

   initialize: function (el, url, options) {
      options = Object.merge(
         {
            fxOptions: null,
            className: options.className || 'blurb-finder',
            maxChoices: 5,
            truncateText: false,
            showImage: true,
            overflow: false,
            postVar: 'search',
            injectChoice: this.injectChoice.bind(this),
            filterSubset: true,
            onShow: this.onShow.bind(this),
         },
         options
      );

      this.el = el;

      this.parent(this.el, url, options);

      // Prevent blur event from firing in Safari when dragging the scrollbar
      this.choices.addEvent('mousedown', ev => {
         ev.stop();
      });
   },

   onShow: function (el, choices) {
      let blurbDimens = choices.getElement('.blurb-item').getDimensions(true);
      let blurbWidth = blurbDimens.width;
      let choiceSpacing = 20; // padding of choice + margin-right on image
      let choiceText = choices.getElements('.blurb-text');
      let choiceImage = choices.getElement('.blurb-image');
      let imageWidth = choiceImage.getDimensions(true).width;

      let choiceTextWidth = blurbWidth - imageWidth - choiceSpacing;

      choiceText.each(text => {
         text.setStyle('width', choiceTextWidth);
      });
   },

   injectChoice: function (blurb) {
      if (!this.el.isDisplayed()) {
         return;
      }

      let text =
         blurb.text && this.options.truncateText
            ? truncate(blurb.text, this.options.truncateText)
            : blurb.text;
      text = this.markQueryValue(text ? text : '');

      let data = {
         showImage: this.options.showImage,
         blurbImage: blurb.mini,
         title: blurb.displayTitle,
         displayTitle: this.markQueryValue(blurb.displayTitle),
         summary: text,
      };

      data.blurbId = blurb.newItem ? 'blurb-new' : 'blurb-' + blurb.type + '-' + blurb.id;

      let template = window.hbsTemplates('BLURB_HBS');

      let choice = template(data);

      choice.store('blurb', blurb);
      choice.inputValue = this.options.getValue
         ? this.options.getValue(blurb)
         : blurb.type + ':' + blurb.id;

      if (blurb.newItem) {
         choice.addClass('blurb-add-new');
      }

      this.addChoiceEvents(choice).inject(this.choices);
   },
}));
