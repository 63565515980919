onDomReady(() => {
   if ($('notifications')) {
      Notifications.initialize();
   }
});

/**
 * This is the fun little JS that deals with making sure notifications show up
 * to the user, and lets the user close them all ajaxy like.
 *
 * NOTE: The FAQ notification must be done here because of the way we cache the page.
 */
// eslint-disable-next-line no-var
export var Notifications = (window.Notifications = {
   container: null,

   initialize: function () {
      this.container = $('notifications');

      let notifications = $$('#notifications .notification');

      // We're all done if we have no notifications to display.
      if (notifications.length === 0) {
         return false;
      }

      let msgIds = notifications.map(el => el.get('id'));

      this.container.setStyles({
         position: Browser.ie ? 'absolute' : 'fixed',
         top: '0px',
         left: '0px',
      });

      // Offset the body content to make room for the notifications.
      let height = this.getContainerHeight();
      let oldBGY = this.getBGY();
      let newBGY = oldBGY + height;

      $(document.body).setStyle('padding-top', height);
      this.setBGY(newBGY);

      this.container.show();

      // Send delete command to php for all messages.
      this.container.addEvent('click', ev => {
         ev.stop();
         new Request.AjaxIO('ajaxRemoveNotification').send(msgIds);

         $(document.body).setStyle('padding-top', 0);

         this.setBGY(oldBGY);

         // Hide notifications if deleted.
         $$('.notification').each(el => {
            if (el !== null) {
               el.remove();
            }
         });

         if (this.container != null) {
            this.container.remove();
         }
      });

      this.container.getChildren('div').each(el => {
         el.getChildren('a').each(elm => {
            elm.addEvent('click', event => {
               // this will prevent the event from bubbling up
               event.stopPropagation();
            });
         });
      });
   },

   getContainerHeight: function () {
      let height;
      this.container.makeInvisible().show();
      height = this.container.getSize().y;
      this.container.hide().makeVisible();
      return height;
   },

   getBGY: function () {
      let body = $(document.body);
      let style =
         body.getStyle('background-position-y') || body.getStyle('background-position') || '';
      let match = style.match(/(-?\d+)px$/);
      return match ? parseInt(match[1], 10) : 0;
   },

   setBGY: function (y) {
      let body = $(document.body);
      if (body.getStyle('background-position-y')) {
         body.setStyle('background-position-y', y + 'px');
      } else {
         let current = body.getStyle('background-position');
         let x = current.split(/ +/)[0];
         body.setStyle('background-position', x + ' ' + y + 'px');
      }
   },

   /**
    * Static function to get the height of the notifications. I found myself
    * doing this a lot, so I thought I'd better make it a function.
    */
   getHeight: function () {
      let notificationHeight = 0;

      if ($('notifications')) {
         notificationHeight = $('notifications').getHeight();
      }

      return notificationHeight;
   },
});
