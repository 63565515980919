import { Autocompleter } from 'Shared/Autocompleter/Autocompleter';
import { createRoot } from 'react-dom/client';

// eslint-disable-next-line no-var
export var TeamFinder = (window.TeamFinder = new Class({
   Extends: Autocompleter.Request.API_2_0,

   el: null,

   initialize: function (el, options) {
      if (!App.noAvatarUrl) {
         console.error('App.noAvatarUrl is not set.');
      }
      options = Object.merge(
         {
            className: 'blurb-finder',
            fxOptions: null,
            maxChoices: 5,
            delay: 100,
            truncateText: false,
            overflow: false,
            injectChoice: this.injectChoice.bind(this),
         },
         options
      );

      this.el = el;
      this.maxChoices = options.maxChoices;

      this.parent(this.el, 'teams/search', options);
      this.url = this.request.request.options.url;

      // Prevent blur event from firing in Safari when dragging the scrollbar
      this.choices.addEvent('mousedown', ev => {
         ev.stop();
      });
   },

   query: function () {
      this.toggleIndicator(true);

      let data = Object.clone(this.options.getData) || {};
      let endpoint = this.url + '/' + this.queryValue;
      let queryParams = '?limit=' + this.maxChoices;

      this.request.request.options.url = endpoint + queryParams;

      this.fireEvent('onRequest', [this.element, this.request, data, this.queryValue]);
      this.request.send({ data: data });
   },

   injectChoice: function (team) {
      if (!this.el.isDisplayed()) {
         return;
      }

      const imageUrl = team.imageUrl || App.noAvatarUrl;

      let choice = document.createElement('li');
      choice.className = 'blurb-item';
      choice.id = 'team-' + team.teamid;
      choice.dataset.teamid = team.teamid;

      const root = createRoot(choice);
      root.render(
         <>
            <div className="blurb-image one-one">
               <img src={imageUrl} alt={team.name + ' Avatar'} />
            </div>
            <div className="blurb-text">
               <p className="blurb-title">{team.name}</p>
               <p>Teamid: {team.teamid}</p>
            </div>
         </>
      );

      choice.inputValue = '';

      this.addChoiceEvents(choice).inject(this.choices);
   },
}));
