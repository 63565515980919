import { _js } from '@ifixit/localize';
import { LoadingStatus, Modal } from 'Shared/modal';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('ModeratorVoteFrameModule', () => {
   // ModeratorVote is used by other frame module scripts, so it needs to be in
   // the global scope. The ModeratorVoteFrameModule must still be included for
   // those scripts to work.
   window.ModeratorVote = {
      adminForce: true,
      loading: false,
   };

   let lastResponse;
   let dataToSend;
   let target_docid = null;

   let openTargetModal = function (response) {
      lastResponse = response;
      Modal.doneLoading();
      let ele = new Element('div');
      ele.set('html', response.html);
      Modal.open({
         type: 'element',
         element: ele,
      });
      let submitLink = $('moderatorVoteTargetSubmit');
      dataToSend = {
         doctype: response.doctype,
         docid: response.docid,
         action: response.action,
         adminForce: ModeratorVote.adminForce,
      };

      $('moderatorVoteTargetDocid').addEvent('focus', ev => {
         $('moderatorVoteTargetRadioOther').set('checked', true);
      });
      // We have to get the submit link and make it do this.
      submitLink.addEvent('click', handleTargetSubmit);
   };

   // eslint-disable-next-line no-var
   var handleTargetSubmit = function (ev) {
      ev.stop();
      let target_docid_box_value = $('moderatorVoteTargetDocid').get('value');
      let radioButtons = $$('#moderatorVoteMenu .moderatorVoteTargetRadio');
      target_docid = null;
      radioButtons.each(el => {
         if (el.checked) {
            target_docid = el.get('value');
         }
      });
      if (target_docid == 'box') {
         target_docid = target_docid_box_value;
      }

      if (target_docid == null) {
         target_docid = '';
      }

      target_docid = target_docid.trim();

      if (target_docid == '') {
         // Empty target_docid. Bad.
         Modal.open({
            type: 'message',
            message: 'Please select a target before proceeding.',
            buttons: {
               Ok: function () {
                  openTargetModal(lastResponse);
               },
            },
         });
      } else {
         dataToSend.target_docid = target_docid;
         dataToSend.adminForce = ModeratorVote.adminForce;
         dataToSend.currentSort = getCurrentSortMethod();
         ModeratorVote.voteRequest.send(dataToSend);
      }
   };

   ModeratorVote.targetRequest = new Request.AjaxIO('getTargetModal', {
      onSuccess: openTargetModal,
   });

   ModeratorVote.voteRequest = new Request.AjaxIO('doModeratorVote', {
      onSuccess: function (response) {
         Modal.doneLoading();
         ModeratorVote.doneLoading();
         ModeratorVote.hideChoosePostLinks();
         if (response.error != null) {
            Modal.open({
               type: 'message',
               message: response.error,
               buttons: {
                  Ok: function () {
                     window.location.reload();
                  },
               },
            });
         } else if (response.result == 'passed') {
            if (Modal.currentState) {
               // If result is canceled or success, and Modal was open...
               Modal.loading('Reloading page...');
            } else {
               ModeratorVote.showLoadingOnElement(
                  ModeratorVote.lastLoadingElement,
                  'Reloading page...'
               );
            }
            const currentPath = window.location.href.replace(window.location.origin, '');
            if (response.link != null && response.link != currentPath) {
               window.location = response.link;
            } else {
               window.location.reload();
            }
         } else {
            if (Modal.currentState) {
               Modal.close();
            }
         }
      },
   });

   ModeratorVote.setupLinkActions = function () {
      $('content').addEvents({
         'click:relay(.js-vote-flag-link, .voteFlagLink)': function (ev, link) {
            ev.stop();
            let docid = link.get('data-docid');
            let doctype = link.get('data-doctype');
            let doc = link.getParent('.qaPost');

            ModeratorVote.showLoadingOnElement(doc, '_js("Loading menu...")');
            let extraData = {
               questionid: ModeratorVote.questionid,
            };
            ModeratorVote.menuRequest.send(doctype, docid, extraData);
            ModeratorVote.lastDoctype = doctype;
            ModeratorVote.lastDocid = docid;
            ModeratorVote.lastExtraData = extraData;
         },
      });
   };

   /**
    * We used to use this after the vote was cast. Now we don't, but I'm
    * keeping it in here for a bit just in case!
    *
   ModeratorVote.reopenMenu = function() {
      if (this.lastDoctype != null)
         this.menuRequest.send(this.lastDoctype, this.lastDocid,
          this.lastExtraData);
   }
   */

   ModeratorVote.menuRequest = new Request.AjaxIO('getModeratorVotes', {
      onSuccess: function (response) {
         // Bring up the vote menu.
         let element = new Element('div');
         element.set('html', response.html);
         /* element.getElement() won't find things by id if the element
            hasn't been added to the DOM, so we find by class. */
         let adminForceBox = element.getElement('.adminForce');
         if (adminForceBox != null) {
            // Set adminForce to the old value that we were just using.
            adminForceBox.checked = ModeratorVote.adminForce;
         }
         let answerCount = $$('.answerToQuestion, .js-post-answer').length;

         element.getElements('.moderatorVote').each(el => {
            if (el.hasClass('disabledButton')) {
               return;
            }

            let docid = el.get('data-docid');
            let doctype = el.get('data-doctype');
            let action = el.get('data-action');
            let cancel = el.get('data-cancel');
            let target = el.get('data-target');
            let data = {
               doctype: doctype,
               docid: docid,
               action: action,
               target_docid: null,
               questionid: ModeratorVote.questionid,
               cancel: cancel,
               currentSort: getCurrentSortMethod(),
            };

            if (doctype == 'posts' && action == 'accept_an_answer_vote') {
               data.target_docid = ModeratorVote.questionid;
            }

            // Cast Vote
            if (
               target == 'false' ||
               cancel == 'true' ||
               (doctype == 'posts' &&
                  action == 'switch_answer_to_comment_vote' &&
                  answerCount == 1) ||
               action == 'accept_an_answer_vote'
            ) {
               el.addEvent('click', ev => {
                  ev.stop();
                  ModeratorVote.adminForce = data.adminForce =
                     $('adminForce') != null && $('adminForce').checked;

                  if (
                     doctype == 'posts' &&
                     (action == 'switch_answer_to_comment_vote' ||
                        action == 'accept_an_answer_vote')
                  ) {
                     data.target_docid = ModeratorVote.questionid;
                  }

                  if (cancel == 'true') {
                     Modal.loading(_js('Canceling vote...'));
                  } else {
                     Modal.loading(_js('Casting vote...'));
                  }

                  ModeratorVote.voteRequest.send(data);
               });
            } else if (
               (doctype == 'posts' &&
                  action == 'switch_answer_to_comment_vote' &&
                  answerCount > 1) ||
               (doctype == 'comments' && action == 'comment_to_update_vote')
            ) {
               // Use Post Picker
               el.addEvent('click', ev => {
                  ModeratorVote.adminForce = data.adminForce =
                     $('adminForce') != null && $('adminForce').checked;
                  Modal.loading(_js('Loading...'));
                  ModeratorVote.postPickerRequest.send(data);
               });
            } else {
               // Use Target Modal
               el.addEvent('click', ev => {
                  ev.stop();
                  ModeratorVote.adminForce = $('adminForce') != null && $('adminForce').checked;
                  Modal.loading(_js('Loading...'));
                  ModeratorVote.targetRequest.send(doctype, docid, action);
               });
            }
         });

         Modal.doneLoading();
         ModeratorVote.doneLoading();

         Modal.open({
            type: 'element',
            element: element,
         });
      },
   });

   ModeratorVote.hideChoosePostLinks = function () {
      let editControls = $$('.qaEditControls');
      let choosePostLinks = $$('.choosePostLink');
      let cancelChoosePostContainer = $$('.cancelChoosePostContainer');

      editControls.each(editControl => {
         editControl.removeClass('hidden');
      });
      choosePostLinks.each(link => {
         link.addClass('hidden');
      });
      cancelChoosePostContainer.each(link => {
         link.addClass('hidden');
      });
   };

   /**
    * All the stuff regarding choosePost links
    * lives in Answers/post.phtml.
    */
   ModeratorVote.postPickerRequest = new Request.AjaxIO('getTargetButtonInfo', {
      onSuccess: function (response) {
         Modal.doneLoading();
         Modal.close();
         let editControls = $$('.qaEditControls');
         let choosePostLinks = $$('.choosePostLink');
         let cancelChoosePostContainer = $$('.cancelChoosePostContainer');

         editControls.each(editControl => {
            editControl.addClass('hidden');
         });

         $$('.cancelChoosePostLink').each(cancelLink => {
            cancelLink.addEvent('click', ev => {
               ev.stop();
               ModeratorVote.hideChoosePostLinks();
            });
         });

         let buttonText;
         // Set up choosePost buttons.
         choosePostLinks.each(choosePostLink => {
            let targetPostid = choosePostLink.get('data-postid');
            buttonText = response.buttonText;

            if (!ModeratorVote.adminForce) {
               buttonText += ' - ';
               buttonText +=
                  response.voteCounts[targetPostid] == null
                     ? '0'
                     : response.voteCounts[targetPostid];

               buttonText += ' / ' + response.threshold + (' ' + _js('Votes'));
            }

            choosePostLink.set('text', buttonText);
            if (targetPostid == response.docid && response.doctype == 'posts') {
               $('cancelChoosePostContainer' + targetPostid).removeClass('hidden');
               return;
               /* We don't want to put a link to put a comment on the
                * answer that we're deleting. */
            }
            choosePostLink.removeClass('hidden');
            choosePostLink.removeEvents('click');
            choosePostLink.addEvent('click', evt => {
               evt.stop();
               let data = {
                  target_docid: targetPostid,
                  doctype: response.doctype,
                  docid: response.docid,
                  action: response.action,
                  adminForce: ModeratorVote.adminForce,
                  questionid: ModeratorVote.questionid,
                  currentSort: getCurrentSortMethod(),
               };
               let doc = choosePostLink.getParent('.qaPost');
               ModeratorVote.showLoadingOnElement(doc, _js('Casting vote...'));
               ModeratorVote.voteRequest.send(data);
            });
         });
      },
   });

   ModeratorVote.showLoadingOnElement = function (element, msg1, msg2) {
      if (element != null) {
         if (ModeratorVote.loading) {
            ModeratorVote.loadingStatus.update(msg1, msg2);
         } else {
            ModeratorVote.loadingStatus = new LoadingStatus(element);
            ModeratorVote.loadingStatus.loading(msg1, msg2);
         }
         ModeratorVote.loading = true;
         ModeratorVote.lastLoadingElement = element;
         return true;
      }
      return false;
   };

   ModeratorVote.doneLoading = function () {
      if (ModeratorVote.loading) {
         ModeratorVote.loadingStatus.doneLoading();
         ModeratorVote.loading = false;
         return true;
      }
      return false;
   };

   /* We make this a function so that we can call it again if we add new
    * links to the page later. */
   ModeratorVote.setupLinkActions();
   let questionidSpan = $('questionidSpan');
   ModeratorVote.questionid = questionidSpan == null ? null : questionidSpan.get('text');

   let getCurrentSortMethod = function () {
      const SORT_DEFAULT = 'Default'; // Matches Question::SORT_DEFAULT

      function getSortParamValue() {
         const url = new URL(window.location.href);
         const params = new URLSearchParams(url.search);
         return params.get('sort');
      }

      let activeFilterTab = document.querySelector('.filter-option-active');
      return (
         getSortParamValue() || (activeFilterTab && activeFilterTab.dataset.filter) || SORT_DEFAULT
      );
   };
});
