import { Modal } from 'Shared/modal';

onDomReady(() => {
   $$('.keyboard-shortcuts').addEvent('click', () => {
      Modal.open({
         type: 'element',
         element: $('shortcuts-modal'),
      });
   });
});
