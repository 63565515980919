import simple from 'Shared/Templates/dev/simple.hbs';
import guide_meta_prereq_list from 'Shared/Templates/guide_meta_prereq_list.hbs';
import guide_meta_item from 'Shared/Templates/guide_meta_item.hbs';
import prereq_list_item from 'Shared/Templates/prereq_list_item.hbs';
import repair_service_row from 'Shared/Templates/repair_service_row.hbs';
import wiki_edit_document from 'Shared/Templates/wiki_edit_document.hbs';

function getter(json) {
   return function (key) {
      // eslint-disable-next-line
      if (!json.hasOwnProperty(key)) {
         let errorStr = 'Property ' + key + ' does not exist!';
         throw errorStr;
      }
      return json[key];
   };
}

window.guideHbsTemplates = getter({
   DEV_SIMPLE_HBS: Template.get(simple),
   GUIDE_META_ITEM_HBS: Template.get(guide_meta_item),
   GUIDE_META_PREREQ_LIST_HBS: Template.get(guide_meta_prereq_list),
   PREREQLISTITEM_HBS: Template.get(prereq_list_item),
   REPAIR_SERVICE_ROW_HBS: Template.get(repair_service_row),
   WIKI_EDIT_DOCUMENT_HBS: Template.get(wiki_edit_document),
});
