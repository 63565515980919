const createLinksModifier = affiliateConfig => ({
   'amazon.com': link => {
      const url = new URL(link);
      url.searchParams.set('tag', affiliateConfig['amazon.com']);
      return url.toString();
   },
   'amzn.com': link => {
      const url = new URL(link);
      url.searchParams.set('tag', affiliateConfig['amzn.com']);
      return url.toString();
   },
   'ebay.com': link => {
      const url = new URL(affiliateConfig['ebay.com']);
      url.searchParams.set('mpre', link);
      return url.toString();
   },
   'etradesupply.com': link => {
      const url = new URL(link);
      url.searchParams.set('acc', affiliateConfig['etradesupply.com']);
      return url.toString();
   },
});

export default createLinksModifier;
