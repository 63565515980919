onDomReady(() => {
   Icons.addEvents();
});

// eslint-disable-next-line no-var
export var Icons = (window.Icons = {
   addEvents: function (el, force) {
      if (!el) {
         el = $(document.body);
      }

      let toggleImages = function (el) {
         let imgs = el.getElements('img');
         if (imgs.length == 2) {
            imgs.invoke('toggleClass', 'hidden');
         }
      };

      el.getElements('.iconLink').each(icon => {
         if (force || !icon.retrieve('iconEventAdded', false)) {
            let fn = toggleImages.pass(icon);
            icon.store('iconEventAdded', true);
            icon.addEvents({
               mouseenter: fn,
               mouseleave: fn,
            });
         }
      });

      return el;
   },
});
