import { SuggestDevices } from 'Shared/suggest';

// eslint-disable-next-line no-var
export const DeviceFinder = {
   initialize: function (input, options) {
      this.options = options || {};

      let finder = new SuggestDevices(
         input,
         Object.merge(
            {
               langid: App.lang ? App.lang : null,
               fallbackToDefaultLanguage: true,
               maxChoices: 6,
               doctypes: 'device',
               showImages: true,
            },
            this.options
         )
      );

      if (this.options.onHide) {
         input.addEvent('keyup:pause(200)', this.options.onHide.bind(finder));
      }
   },
};
