import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { Flex } from '@chakra-ui/react';
import { BreadCrumbs, BreadcrumbData } from '@ifixit/carpenter-breadcrumbs/Breadcrumbs';

const NavBreadcrumbs = ({ breadcrumbs }: { breadcrumbs: BreadcrumbData[] }) => {
   if (!breadcrumbs) {
      return null;
   }

   return (
      <Flex
         height="100%"
         paddingLeft={4}
         width="100%"
         max-width="770px"
         breadcrumbs-testid="breadcrumb-container"
      >
         <BreadCrumbs breadCrumbs={breadcrumbs} />
      </Flex>
   );
};

initializeChakraComponent('NavBreadcrumbs', NavBreadcrumbs);
