import { _js } from '@ifixit/localize';
import { Utils } from 'Shared/utils';
import { SuggestAuthors } from 'Shared/suggest';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('AuthorEditFrameModule', () => {
   window.AuthorEditor = {
      options: {
         max: 999,
         hideOnMax: true,
         controls: true,
         form: $('author-edit-form'),
         formManager: null,
         input: $('author-input'),
         inputContainer: $('author-input-container'),
         container: $('author-name-container'),
         cancelControl: $('cancel-author-change'),
         control: $('edit-author-button'),
         customChoicesContainer: $('author-search-choices'),
         multiple: false,
         overflow: true,

         currentAuthorName: $('current-author-name'),
         currentAuthorImage: $('current-author-image'),
      },

      initialize: function (options) {
         this.setOptions(options);

         this.form = this.options.form;
         this.container = this.options.container;
         this.control = this.options.control;
         this.cancelControl = this.options.cancelControl;
         this.input = this.options.input;
         this.inputContainer = this.options.inputContainer;
         this.customChoicesContainer = this.options.customChoicesContainer;

         this.docid = this.form.guideid.value;

         this.currentAuthorName = this.options.currentAuthorName;
         this.currentAuthorImage = this.options.currentAuthorImage;

         let currentName = App.authorInfo.username;
         if (App.authorInfo.unique_username) {
            currentName += ' (@' + App.authorInfo.unique_username + ')';
         }
         this.currentAuthorInfo = {
            name: currentName,
            image: App.authorInfo.image,
         };
         this.newAuthorInfo = {};

         let openEdit = function (e) {
            if (e) {
               e.stop();
            }
            this.input.set('value', '');
            this.inputContainer.show();
            this.input.focus();
            this.newAuthorInfo = {};

            this.input.addEvent('blur', e => {
               e.stop();

               this.currentAuthorName.set(
                  'text',
                  this.newAuthorInfo.name ? this.newAuthorInfo.name : this.currentAuthorInfo.name
               );

               this.currentAuthorImage.set(
                  'src',
                  this.newAuthorInfo.image ? this.newAuthorInfo.image : this.currentAuthorInfo.image
               );

               this.inputContainer.hide();
            });
         }.bind(this);
         this.control.addEvent('click', openEdit);

         // Add the autocompleter.
         new SuggestAuthors(this.options.input, {
            customChoices: this.customChoicesContainer,
            maxChoices: 5,
            multiple: false,
            onSelect: function (input, autocompleteItem, finish) {
               this.currentAuthorName.set('text', autocompleteItem.get('data-username'));
               this.currentAuthorImage.set('src', autocompleteItem.get('data-image'));
            }.bind(this),
            onSelection: function (autocompleteItem, finish, queryValue) {
               this.newAuthorInfo = {
                  userid: autocompleteItem.get('data-userid'),
                  name: autocompleteItem.get('data-username'),
                  image: autocompleteItem.get('data-image'),
               };

               this.control.innerHTML = _js('Save');
               this.control.removeEvents();
               this.control.addEvent('click', e => {
                  this.saveAuthor();
                  this.cancelControl.hide();
                  this.control.removeEvents();
                  this.control.innerHTML = _js('Edit');
                  this.control.addEvent('click', openEdit);
               });

               this.cancelControl.addEvent('click', e => {
                  this.cancelControl.hide();
                  this.currentAuthorName.set('text', this.currentAuthorInfo.name);

                  this.currentAuthorImage.set('src', this.currentAuthorInfo.image);

                  this.control.innerHTML = _js('Edit');
                  this.control.removeEvents();
                  this.control.addEvent('click', openEdit);
               });
               this.cancelControl.show('inline-block');

               this.inputContainer.hide();
            }.bind(this),
            relative: true,
         });

         return this;
      },

      saveAuthor: function () {
         new Request.API_2_0('guides/' + App.guideApi.guideid, {
            queryParams: { revisionid: App.guideApi.revisionid },
            method: 'PATCH',
            statusPanelMessage: _js('Saving...'),
            onSuccess: function (response) {
               this.control.innerHTML = _js('Edit');
               this.currentAuthorInfo.name = response.author.username;
               if (response.author.unique_username) {
                  this.currentAuthorInfo.name += ' (@' + response.author.unique_username + ')';
               }
               this.currentAuthorInfo.image = response.author.image.mini;

               // Update revisionids for any subsequent requests. For some reason we
               // use these variables interchangeably.
               App.guideApi.revisionid = response.revisionid;
               App.guide_revisionid = response.revisionid;

               // Even though we're updating the global revisionid variables,
               // it's still probably safer to refresh the page and avoid state
               // getting out of sync.
               window.location.reload();
            }.bind(this),
         }).send({ author: this.newAuthorInfo.userid });
      },
   };
   Object.append(AuthorEditor, Utils.EventsFunctions);
   Object.append(AuthorEditor, new Options());
});
