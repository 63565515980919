import { _js } from '@ifixit/localize';
import { Utils } from 'Shared/utils';
import { Icons } from 'Guide/icons';
import { Modal } from 'Shared/modal';
import { SuggestTags } from 'Shared/suggest';
import { FormManager } from 'Shared/form_manager';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('TagsEditFrameModule', () => {
   window.TagEditor = {
      options: {
         max: 999,
         hideOnMax: true,
         controls: true,
         form: $('tagsEditForm'),
         formManager: null,
         input: $('tagsEdit'),
         container: $('tagList'),
         control: $E('.saveTagsControl'),
      },

      initialize: function (options) {
         this.setOptions(options);

         this.form = this.options.form;
         this.container = this.options.container;
         this.docid = this.form.docid.value;
         this.doctype = this.form.doctype.value;

         this.input = this.options.input;

         // Use the passed-in formManager, if the tags edit form is embedded in a
         // larger form, or make our own.
         let fm = this.options.formManager ? this.options.formManager : new FormManager(this.form);

         this.tags = [];

         // Add the submit event.
         let onSubmit = function (e) {
            if (e) {
               e.stop();
            }
            this.submitTags();
         }.bind(this);

         // Clicking the 'add' button submits.
         $('tagsAdd').addEvent('click', onSubmit);

         // Add the autocompleter.
         new SuggestTags(this.options.input, {
            onSelection: function () {
               onSubmit(null);
            },
         });

         // Add the delete events.
         this.container.getChildren().each(li => {
            // Skip over any dummy <li>s, put in for validation purposes when the
            // <ul> would otherwise be empty.
            if (li.getStyle('display') == 'none') {
               li.dispose();
               return;
            }
            this.setup(li, true);
         });

         this.control = this.options.control;
         if (this.control) {
            this.control.getElement('a[href$=save-tags]').addEvent('click', this.save.bind(this));
            this.control
               .getElement('a[href$=cancel-tags]')
               .addEvent('click', this.cancel.bind(this));
         }

         this.savedTags = [].append(this.tags);
         this.fireEvent('change', [this.tags]);

         return this;
      },

      //save: function(ev) {
      save: function (ev, callback) {
         ev.stop();
         new Request.AjaxIO('saveTags', {
            onSuccess: function (responseData) {
               if (callback) {
                  callback();
               }
               if (responseData.error) {
                  Modal.alert(responseData.error);
                  return; // get out of here.
               }

               let tags = responseData.tags;
               when($('revisionid'), input => {
                  if (responseData.revisionid != null) {
                     input.set('value', responseData.revisionid);
                  }
               });
               this.tags = [];
               this.container.empty();
               tags.each(tag => {
                  this.addTag(tag, true);
               });
               this.savedTags = [].append(this.tags);
               this.control.hide();
               this.fireEvent('change', [this.tags]);
               this.fireEvent('done');
            }.bind(this),
         }).send(this.docid, this.doctype, this.tags);
      },

      cancel: function (ev) {
         ev.stop();

         this.tags = [];
         this.container.empty();
         this.savedTags.each(tag => {
            this.addTag(tag, true);
         });

         this.control.hide();
         this.checkMax();
         this.fireEvent('change', [this.tags]);
         this.fireEvent('done');
      },

      isLegacyTagStyle: function () {
         return this.container.hasClass('legacyTagStyle');
      },

      addTag: function (tag, stored) {
         if (!this.tags.contains(tag) && !this.tags.contains('#' + tag)) {
            let li = new Element('li', { class: 'js-tag', 'data-tag': tag });
            if (this.isLegacyTagStyle()) {
               li = new Element('li');
               const tagText = new Element('span', { class: 'tag-text' }).set('text', tag);
               tagText.inject(li);
            } else {
               li.adopt(new Element('i', { class: 'fa fa-circle-o' }));
               li.adopt(
                  new Element('i', { class: 'fa fa-times js-tag-delete', style: 'display: none' })
               );
               li.adopt(new Element('span', { class: 'tag-text', text: tag }));
            }
            li.inject(this.container);
            this.container.show();
            this.container.addClass('showFlex');
            this.setup(li, stored);
            this.showControls();
         }
      },

      submitTags: function () {
         new Request.AjaxIO('addTags', {
            onSuccess: function (tags) {
               tags.each(function (tag) {
                  let tagCount = this.tags.length;
                  if (++tagCount <= this.options.max) {
                     this.addTag(tag);
                  } else {
                     let maxCountMessage = _js('The maximum number of tags is four.') + ' ';
                     this.showStatusMessage(maxCountMessage, $('tagInput'));
                  }
               }, this);
               this.fireEvent('change', [this.tags]);
            }.bind(this),
         }).send(this.input.value);
         this.input.value = '';
      },

      removeTag: function (tag) {
         this.showControls();
         this.tags = this.tags.filter(el => el.trim() != tag);

         this.container.getChildren().each(li => {
            if (li.retrieve('tag') == tag) {
               if (li.retrieve('saved')) {
                  li.set('html', tag);
                  li.setStyle('text-decoration', 'line-through');
               } else {
                  li.destroy();
               }
            }
         });

         this.checkMax();
         this.fireEvent('change', [this.tags]);
      },

      /**
       * Creates the anchor element for removing a tag.
       */
      setup: function (li, saved) {
         // Get child element if tag is empty. This tag editor logic is
         // used in multiple places where the html is not always the same.
         let tag = li.get('text').trim() || li.getFirst().get('text').trim();

         li.store('tag', tag);
         li.store('saved', saved);

         li.addEvent('click:relay(.js-tag-delete)', (ev, el) => {
            ev.stop();
            let tag = el.getParent().get('data-tag').trim();

            this.removeTag(tag);

            el.getParent().destroy();
         });

         when($('removeTag'), el => {
            // Delete link icon
            let remove = el.getFirst().clone();
            remove
               .addEvent('click', ev => {
                  ev.stop();
                  this.removeTag(tag);
               })
               .inject(li);
         });

         // Synonymize link icon
         when($('synonymizeTag'), div => {
            let synonymize = div.getFirst().clone();
            synonymize
               .addEvent('click', ev => {
                  ev.stop();
                  this.synonymizeTag(tag);
               })
               .inject(li);
         });
         Icons.addEvents(li);

         this.tags.combine([tag]);
         this.checkMax();
      },

      synonymizeTag: function (tag) {
         let text = prompt(
            "CREATING NEW AUTOMATIC TAG SYNONYM\n\nBe careful with this.\n\nAlways replace tag '" +
               tag +
               "' with:"
         );
         if (text) {
            new Request.AjaxIO('addSynonym', {
               onSuccess: function (success) {
                  if (success) {
                     this.removeTag(tag);
                     this.addTag(text);
                  }
               }.bind(this),
            }).send(this.docid, this.doctype, tag, text);
         }
      },

      checkMax: function () {
         if (this.tags.length < this.options.max) {
            $('tagInput').show();
            $('tagsEditStatusMessage').hide();
            return false;
         }

         if (this.options.hideOnMax) {
            $('tagInput').hide();
         }

         return true;
      },

      showStatusMessage: function (msg, el) {
         $('tagsEditStatusMessage').setProperty('text', msg).inject(el, 'after').show();
      },

      showControls: function () {
         if (this.options.controls) {
            this.control.show();
         }
      },
   };

   Object.append(TagEditor, Utils.EventsFunctions);
   Object.append(TagEditor, new Options());
});
