import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('UserFeedbackFrameModule', () => {
   $$('.user_feedback_vote_button').addEvent('click', function (event) {
      let voteButton = this; // Easier to understand.
      if (voteButton.hasClass('disabled')) {
         return;
      }

      // What url we post a vote request to.
      let voteUrl = voteButton.get('data-url');

      let voteData = {
         doctype: voteButton.get('data-doctype'),
         docid: voteButton.get('data-docid'),
         score: voteButton.get('data-score'),
      };

      let scoreid = voteButton.get('data-scoreid');

      // We may not show the score anywhere, but if we do, we'll want to update it.
      let scoreElement = scoreid ? $(scoreid) : null;
      let curScoreInt = scoreElement ? Number.parseInt(scoreElement.get('html'), 10) : 0;
      let scoreChangeInt = Number.parseInt(voteData.score, 10);

      let otherButton;
      let newScoreInt;

      otherButton = voteButton.hasClass('down')
         ? voteButton.getParent().getChildren('.up')[0]
         : voteButton.getParent().getChildren('.down')[0];

      if (voteButton.hasClass('active')) {
         // Cancel
         newScoreInt = curScoreInt - scoreChangeInt;
         voteButton.removeClass('active');
      } else if (otherButton.hasClass('active')) {
         // Change
         let otherButtonScoreChangeInt = Number.parseInt(otherButton.get('data-score'), 10);
         newScoreInt = curScoreInt + scoreChangeInt - otherButtonScoreChangeInt;
         voteButton.addClass('active');
         otherButton.removeClass('active');
      } else {
         // Normal
         newScoreInt = curScoreInt + scoreChangeInt;
         voteButton.addClass('active');
      }

      voteButton.addClass('disabled');
      otherButton.addClass('disabled');

      voteButton.addClass('inProgress');

      if (scoreElement != null) {
         scoreElement.set('html', newScoreInt);
      }

      /**
       * The dropdown code is in here right now. I'm not sure if we'll
       * end up using it again or what. If the html doesn't have it, it will
       * be ignored. Maybe this should go into a more specific js file?
       * We'll see as this gains more use.
       */
      when($('user_feedback_text_dropdown'), expandable => {
         let textUrl = expandable.get('data-url');
         let textData = {
            doctype: voteButton.get('data-doctype'),
            docid: voteButton.get('data-docid'),
         };

         if (expandable.hasClass('hidden')) {
            expandable.slide('hide');
            expandable.removeClass('hidden');
            expandable.set('slide', {
               duration: 250,
            });
         }
         expandable.slide('in');
         let textarea = expandable.getElement('textarea');

         let maxLength = textarea.get('maxlength').toInt();
         let remainingCharsSpan = expandable.getElement('.user_feedback_text_remaining_chars');
         textarea.addEvent('keyup', function () {
            let curText = this.get('value');
            let newRemainingCount = maxLength - curText.length;
            if (newRemainingCount < 0) {
               curText = curText.slice(0, Math.max(0, maxLength));
               newRemainingCount = 0;
               textarea.set('value', curText);
            }

            remainingCharsSpan.set('text', newRemainingCount);
         });

         $$('.user_feedback_send_button')
            .removeEvents()
            .addEvent('click', ev => {
               ev.stop();
               let text = textarea.get('value');

               textData.text = text;

               expandable.slide('out');

               // TODO: Catch an error if the text is longer than 2000 chars.
               new Request.API_2_0(textUrl, {
                  method: 'post',
                  onSuccess: function (response) {
                     // Nothing I guess.
                  },
               }).send(textData);
            });
      });

      new Request.API_2_0(voteUrl, {
         method: 'post',
         onSuccess: function (response) {
            if (response.voteType == 'anonymous') {
               voteButton.addClass('hidden');
               otherButton.addClass('hidden');
               voteButton.getNext('.user_feedback_vote_thanks_text').removeClass('hidden');
            } else {
               voteButton.removeClass('disabled');
               otherButton.removeClass('disabled');
            }

            voteButton.removeClass('inProgress');
         },
      }).send(voteData);
   });
});
