export class ToggleButton {
   constructor(container, options = {}) {
      this.container = document.getElementById(container);
      this.options = { callback: '', ...options };
      this.buttons = this.container.querySelectorAll('button');
      this.prevToggle = null;

      this.buttons.forEach(button => {
         button.addEventListener('click', event => this.toggle(event));
      });
   }

   toggle(event) {
      event.preventDefault();
      let el =
         event.target.tagName.toLowerCase() === 'button'
            ? event.target
            : event.target.closest('button');
      let value = el.dataset.value;

      if (el.classList.contains('active')) {
         return;
      } else {
         this.setActive(el, 'active');
      }

      if (typeof this.options.callback === 'function') {
         this.options.callback(event);
      }
   }

   untoggle() {
      this.setActive(this.prevToggle, 'active');
   }

   setActive(el, activeClass) {
      let siblings = el.parentNode.querySelectorAll('button');
      siblings.forEach(sib => {
         if (sib.classList.contains(activeClass)) {
            this.prevToggle = sib;
            sib.classList.remove(activeClass);
         }
      });

      el.classList.add(activeClass);
   }
}
