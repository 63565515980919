onDomReady(() => {
   $$('.js-status-cancel').each(el => {
      let notification = el.getParent(),
         container = notification.getParent(),
         id = notification.get('id'),
         clearNotification = function () {
            if (container.getChildren().length <= 1) {
               container.dispose();
            } else {
               notification.dispose();
            }
         };

      el.addEvent('click', ev => {
         clearNotification();
      });
   });
});
