import { Utils } from 'Shared/utils';
import { tween } from 'shifty';

(function () {
   /**
    * Simple dropdown that toggles .content-dropdown-expanded on an
    * .js-content-dropdown-container when its
    * .js-content-dropdown-toggle is clicked, and closes when clicking outside of
    * the dropdown.
    *
    * groupEl: If you pass in a container with multiple
    * .js-content-dropdown-containers in it, this will only allow one dropdown in
    * the group to be open at any given time.
    *
    * .js-content-dropdown-close is for a button that always closes the dropdown,
    * and is an element inside of the dropdown's contents.
    *
    * .content-dropdown-notification is removed as soon as the user interacts with
    * the dropdown once.
    *
    * If the container has overflow:visible, clicking outside of the container
    * will close the dropdown. If overflow:hidden, we assume that the dropdown
    * height pushes other content down, so it will stay open.
    *
    * Use .content-dropdown-default on .js-content-dropdown-container for a
    * default theme.
    */

   let defaults = {
      toggle: 'js-content-dropdown-toggle',
      close: 'js-content-dropdown-close',
      container: 'js-content-dropdown-container',
      cssExpanded: 'content-dropdown-expanded',
      cssNotification: 'content-dropdown-notification',
      forceCloseOpenedDropdown: true,
   };

   window.ContentDropdownGroup = function (groupEl, options) {
      options = Object.merge({}, defaults, options);

      let openedDropdown = null;
      let originalDropdownPosition;

      const dissolve = el => {
         tween({
            from: { opacity: 1 },
            to: { opacity: 0 },
            duration: 100,
            render: ({ opacity }) => {
               el.style.opacity = opacity;
            },
         }).then(() => {
            el.style.display = 'none';
            el.style.opacity = 1;
         });
      };

      const reveal = el => {
         el.style.opacity = 0;
         el.style.display = 'block';

         tween({
            from: { opacity: 0 },
            to: { opacity: 1 },
            duration: 100,
            render: ({ opacity }) => {
               el.style.opacity = opacity;
            },
         });
      };

      let closeDropdown = function () {
         if (openedDropdown !== null) {
            openedDropdown.removeClass(options.cssExpanded);
            openedDropdown.getChildren('.content-dropdown-list').forEach(el => dissolve(el));
            document.removeEvent('scroll', dropdownHider);
            openedDropdown = null;
         }
      };

      // If they clicked anywhere in the document outside of the element,
      // then close it. Don't close it if overflow:hidden, because closing
      // it would change the whole document's height.
      let closeOpenedDropdown = function (ev, el) {
         if (
            options.forceCloseOpenedDropdown &&
            openedDropdown &&
            ev.target &&
            !openedDropdown.contains(ev.target) &&
            !doesDropdownAffectHeight(openedDropdown)
         ) {
            closeDropdown();
         }
      };

      let toggleDropdown = function (ev, el) {
         let container = el.getParent('.' + options.container);

         if (container.hasClass(options.cssExpanded)) {
            // Close this item if it's already open.
            closeDropdown();
         } else {
            // Otherwise, open this item.
            closeDropdown(); // Close any other open items in this group first.
            // Clear any notifications because they've interacted with it
            container.removeClass(options.cssNotification);

            // Open the item and save a reference to it so we can close it later.
            openedDropdown = container.addClass(options.cssExpanded);
            container.getChildren('.content-dropdown-list').forEach(el => reveal(el));

            // Closes open menus when the user scrolls down and is not mobile.
            // Measure how much scrolling happened:
            document.addEvent('scroll', dropdownHider);
            originalDropdownPosition = window.getScroll().y;
         }
      };

      // eslint-disable-next-line no-var
      var doesDropdownAffectHeight = function (el) {
         return el.getStyle('overflow') != 'visible';
      };

      // Watch how much scrolling happened and close the dropdown do something if
      // the threshold was hit.
      // eslint-disable-next-line no-var
      var dropdownHider = Utils.rateLimiter(250, () => {
         // Scrolled up or down.
         if (
            Math.abs(originalDropdownPosition - window.getScroll().y) > 100 && // Close the dropdown, but only on non-mobile views.
            openedDropdown !== null &&
            !doesDropdownAffectHeight(openedDropdown)
         ) {
            closeDropdown();
         }
      });

      window.addEvent('click', closeOpenedDropdown);

      groupEl.addEvent('click:relay(.' + options.toggle + ')', toggleDropdown);
      groupEl.addEvent('click:relay(.' + options.close + ')', closeDropdown);
   };
})();
