import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';
import { tween } from 'shifty';

when($('helptab'), el => {
   let tabInit = function () {
      tween({
         from: { right: -135 },
         to: { right: -95 },
         duration: 600,
         easing: 'bounce',
         render: ({ right }) => {
            el.style.right = right + 'px';
         },
      });
   };

   tabInit.delay(1000);
   el.addEvents({
      mouseenter: function () {
         tween({
            from: { right: -95 },
            to: { right: -40 },
            duration: 200,
            render: ({ right }) => {
               el.style.right = `${right}px`;
            },
         });
      },
      mouseleave: function () {
         tween({
            from: { right: -40 },
            to: { right: -95 },
            duration: 200,
            render: ({ right }) => {
               el.style.right = `${right}px`;
            },
         });
      },
      click: function () {
         trackInPiwikAndGA({
            eventCategory: 'Help Tab',
            eventAction: 'Click',
         });
         return true;
      },
   });
});
