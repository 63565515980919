// For some text areas that aren't within forms (i.e. editing comments, step
// edit) to get the command+enter shortcut, the 'data-submit-button' attribute
// must be added to a parent with the value of the class the submit button has.

window.addEvent('keydown', ev => {
   // Submits the current form on command+enter or ctrl+enter
   if (ev.key == 'enter' && (ev.meta || ev.control)) {
      if (!ev.target.match("textarea, input[type='text']")) {
         return;
      }

      let form = ev.target.getParent('form');
      if (form) {
         form.fireEvent('submit');
      } else {
         // Field isn't within a form, find the submit button
         let formParent = ev.target.getParent('[data-submit-button]');
         if (!formParent) {
            return;
         }

         let buttonName = formParent.get('data-submit-button');
         let submitButton = formParent.getChildren('.' + buttonName)[0];

         submitButton.click();
      }
   }
});
