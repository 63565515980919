export function stripBlankLines(text: string): string {
   return text.replace(/^\n/gm, '');
}

/**
 * Mutates the value of the textarea passed in.
 */
export function stripBlankLinesFromTextArea(el: HTMLTextAreaElement): void {
   el.value = stripBlankLines(el.value);
}

export function truncate(string: string, max: number, trail = '…', atChar?: string): string {
   if (string.length > max) {
      string = string.substring(0, max);
      if (atChar) {
         const index = string.lastIndexOf(atChar);
         if (index !== -1) string = string.substring(0, index);
      }
      string += trail;
   }
   return string;
}
